import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("UserPreferences", {
        attrs: {
          editable: true,
          btnText: "save",
          User: _vm.User,
          userType: _vm.userTypeEnum.Admin,
        },
        on: { btnConfirm: _vm.savePreferences },
      }),
      _c("div", { staticClass: "highlighted-companies" }, [
        _c("span", { staticClass: "highlighted-companies-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("company_user_highlights")) + " "),
        ]),
        _c("span", { staticClass: "highlighted-companies-subtitle" }, [
          _vm._v(" " + _vm._s(_vm.$t("highlighted_companies_subtitle")) + " "),
        ]),
      ]),
      !_vm.loading
        ? _c(
            "div",
            [
              _c(VSimpleTable, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("name")))]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(" " + _vm._s(_vm.$t("highlighted")) + " "),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.companies, function (c, i) {
                    return _c("tr", { key: i }, [
                      _c("td", [_vm._v(_vm._s(c.Company.Name))]),
                      _c(
                        "td",
                        { staticClass: "text-center" },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: {
                                loading: c.Loading,
                                color: "primary",
                                small: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.highlight_company(c)
                                },
                              },
                            },
                            [
                              c.IsHighlighted
                                ? _c(VIcon, { attrs: { small: "" } }, [
                                    _vm._v("mdi-star"),
                                  ])
                                : _c(VIcon, { attrs: { small: "" } }, [
                                    _vm._v("mdi-star-outline"),
                                  ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ],
            1
          )
        : _c(
            VContainer,
            [
              _c(VProgressCircular, {
                staticStyle: { "margin-left": "50%" },
                attrs: { indeterminate: "", size: "70", color: "primary" },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }